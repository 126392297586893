import React from 'react';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Tabs from './../tabs/Tabs.jsx';
import ExperienceSection from './ExperienceSection.jsx';

import tallyLogo from './../../images/logos/tally_logov2_purple.png';
import hubspotLogo from '../../images/logos/hubspot-logo.png';
import rampLogo from '../../images/logos/ramp-logo.png';
// import dfciLogo from './../../images/logos/dfci-logo.png';
import coinbaseLogo from './../../images/logos/coinbase-logo.png';
import instabaseLogo from './../../images/logos/instabase-logo.png';

class Experience extends React.Component {

  constructor() {
    super();

    this.state = {
      category: 'details',
    };

    this.setCategory = this.setCategory.bind(this);
  }

  get tabs() {
    return [
      'details',
      'photos',
      // 'links'
    ];
  }

  setCategory(category) {
    this.setState({category});
  }

  get sectionContent() {
    return [
      {
        id: 'ramp',
        logo: {
          id: 'ramp-logo',
          src: rampLogo
        },
        photos: false,
        details: {
          headers: [
            `senior web engineer`,
            `new york, ny  |  may 22 - present`],
          paragraphs: [
            `architecting and leading CMS migration, as part of ramp.com’s transition from Webflow to 
            custom NextJS, Sanity CMS, and Tailwind app; sourced technical  contractors for migration 
            project, and actively delegating tasks to both them and internal  engineering team; 
            implementing cost-efficient data delivery strategies through strategic caching, CDN usage, 
            and server-side rendering; managing sprint planning and documentation for project through 
            Linear and Slab`,
            `creating lead generation tools, landing pages, and microsites; building app and website 
            layouts from figma designs; deploying programmatic SEO content through Webflow and the.com`,
            `consistently collaborating with business operations, accounting, partnerships, and 
            marketing teams on launches and deliverables; regularly soliciting feedback on my performance`,
            `leveraging anthropic and OpenAI's API for project ideation and code development`,
            `running conversion rate optimization & A/B experiments through LaunchDarkly and Mutiny; 
            analyzing traffic and metrics with Segment, Amplitude, and Looker; auditing and improving 
            webpages’ SEO rankings, and core web vitals with PageSpeed Insights, Ahrefs and Semrush`,
            `developing with JavaScript, NextJS, React, Typescript, HTML, CSS, Sanity, Tailwind CSS`
          ]
        },
        links: [
          {
            icon: '',
            anchor: '',
            text: '',
            desc: ''
          }
        ],
      },
      {
        id: 'coinbase',
        logo: {
          id: 'coinbase-logo',
          src: coinbaseLogo
        },
        photos: false,
        details: {
          headers: [
            `senior software engineer`,
            `san francisco, ca  |  feb. 21 - may. 22`],
          paragraphs: [
            `developed and maintained code deployed on Coinbase’s marketing pages, as well as its highest 
            trafficked, logged-out pages (coinbase.com, coinbase.com/prices, etc); implemented A/B tests 
            centered around increasing email sign ups and improving the SEO rankings of our logged-out 
            pages; validated quality of code with code reviews, unit tests and end-to-end tests.`,
            `worked closely with product managers and designers on my team to create new experiments, 
            components and pages; collaborated with engineers on several other teams to refactor the 
            legacy code of their product’s marketing website – [card, wallet, commerce and prime].coinbase.com.`,
            `as Scrum Master, led daily standups and sprint planning meetings; mentored L4, L3 and intern 
            engineers – regularly pair-programmed, provided feedback and assigned them new tasks.`,
            `developed with JavaScript (ES7), React, React Native, TypeScript, styled-components, 
            HTML, CSS, Contentful.`
          ]
        },
        links: [
          {
            icon: '',
            anchor: '',
            text: '',
            desc: ''
          }
        ],
      },
      // {
      //   id: 'instabase',
      //   logo: {
      //     id: 'instabase-logo',
      //     src: instabaseLogo
      //   },
      //   photos: false,
      //   details: {
      //     headers: [
      //       `software engineer`,
      //       `san francisco, ca  |  may 20 - jan. 21`
      //     ],
      //     paragraphs: [
      //       `shipped and continually supported code for new widgets and interfaces for our B2B, document
      //       workflow platform; refactored legacy code (jQuery, Handlebars) into React 16.8 components.`,
      //       `crated-from-scratch reusable React components for the company’s new design system, Pollen;
      //       ensured components meet high-standards of performance, accessibility, and reliability;
      //       collaborated on Figma designs with designers and translated final prototypes into code.`,
      //       `developed with JavaScript (ES7), React, TypeScript, Styled-Components, Redux, HTML, CSS.`
      //     ]
      //   },
      //   links: [
      //     {
      //       icon: '',
      //       anchor: '',
      //       text: '',
      //       desc: ''
      //     }
      //   ],
      // },
      {
        id: 'hubspot',
        logo: {
          id: 'hubspot-logo',
          src: hubspotLogo
        },
        photos: false,
        details: {
          headers: [
            `software engineer`,
            `cambridge, ma  |  dec. 17 - sep. 19`],
          paragraphs: [
            `engineered and maintained user interfaces for several internal platforms; 
             improved intuitiveness, personalization, and overall flow of interfaces as 
             well implemented new features and fixed bugs/issues; regularly commented and 
             documented code, participated in code reviews, and wrote unit tests.`,
            `worked with JavaScript (ES6 & ES7), React, React Redux, Typescript, HTML, 
            CSS, Canvas (Hubspot’s internal design systems and component library)`,
            `regularly conducted user tests and interviews to measure team's impact 
            and performance.`
            ]
        },
        links: [
          {
            icon: '',
            anchor: '',
            text: '',
            desc: ''
          }
        ],
      },
      // {
      //   id: 'dfci',
      //   logo: {
      //     id: 'dfci-logo',
      //     src: dfciLogo
      //   },
      //   photos: false,
      //   details: {
      //     headers: [
      //       // `front end software engineer  |  ui/ux designer`,
      //       `front end software engineer`,
      //       `boston, ma  |  mar. 17 - dec. 17`],
      //     paragraphs: [
      //       `developed and refactored code for cancer genomic and clinical matching
      //       platforms, and created interfaces for internal projects, such
      //       as user statistic dashboards and medical personnel websites.`,
      //       `worked with javascript, javascript frameworks (react, angular, typescript),
      //       data visualization libraries (c3, d3, chartjs), unit and end-to-end testing libraries
      //       (mocha, enzyme, webdriver.io), as well as basic front end languages
      //       (html, css, scss).`,
      //       `participated in agile/scrum practices, continuous git integration,
      //       and weekly meetings to discuss performance & goals.`]
      //   },
      //   links: [
      //     {
      //       icon: 'special',
      //       anchor: 'http://www.cbioportal.org',
      //       text: 'cbioportal.org',
      //       desc: `provides visualization, analysis,
      //             and downloadable files of cancer genomic data.`
      //     },
      //     {
      //       icon: 'special',
      //       anchor: 'http://www.matchminer.org',
      //       text: 'matchminer.org',
      //       desc: `provides matching criteria for patient specific
      //              genomic profiles to cancer medicine clinical trial.`
      //     },
      //   ],
      // },
      {
        id: 'tally',
        logo: {
          id: 'tally-logo',
          src: tallyLogo
        },
        photos: true,
        details: {
          headers: [
            `co-founder  |  lead frontend engineer  |   lead designer`,
            `cambridge, ma  |  may 16 - mar.18`],
          paragraphs: [
            `Co-founded mobile app that allows friends to collaboratively plan and organize outings.`,
            `developed initial design concepts using sketches, wireframes, mockups, 
            and prototypes.  user-tested and thereafter refined prototypes.`,
            `wrote responsive, frontend code (javascript, react, html, less) for app and its website.`,
            `Successfully launched the app in both the iOS and Android app stores.`
          ],
        },
        links: [
          {
          icon: 'apple-original',
           anchor: '#',
           text: '/appStoreDownload/'
          },
          {
          icon: 'android-plain',
           anchor: '#',
           text: '/playStoreDownload/'
           },
          {
            icon: 'special',
            anchor: 'http://gettally.io',
            text: 'gettally.io'
          },
          // {

        ],
        linkMessage: 'app coming mid february 2018'
      }
    ];
  }

  get sections() {
    // const filteredSections = this.sectionContent.filter(section =>
    //   !!section[this.state.category]
    // );
    // return (
    //   <TransitionGroup className="section-container">
    //     {filteredSections.map(section => (
    //       <CSSTransition
    //         key={section.id}
    //         timeout={600}
    //         classNames="section-transition"
    //       >
    //         <ExperienceSection key={section.id} {...section} category={this.state.category} />
    //       </CSSTransition>
    //     ))}
    //   </TransitionGroup>
    // )
    return this.sectionContent.map((section, i) => (
      <ExperienceSection key={i} {...section} category={this.state.category} />
    ));
  }

  render() {
    return (
      <section id="experience" className="resume-section">
        <div className="flex-line">
          <div className="flex-left">
            <h1>experience</h1>
          </div>
          <Tabs
            tabs={this.tabs}
            tabState={this.state.category}
            setCategory={this.setCategory}
          />
        </div>
        <div>
          {this.sections}
        </div>
      </section>
    );
  }
}

export default Experience;
